<template>
  <div class="agreements">
    <div class="agreements__box">
      <h1>Potwierdź <span style="font-weight:bold">regulamin portalu</span></h1>
      <a-divider />
      <div class="agreements__content">
        <regulation-text />
      </div>
      <div class="agreements__checkbox">
        <a-checkbox v-model:checked="checked">
          <div class="agreements__checkbox--label">
            Potwierdzam zapoznanie się z regulaminem
          </div>
        </a-checkbox>
      </div>
      <a-button
        class="agreements__button"
        type="primary"
        html-type="submit"
        @click="acceptAgreements"
        :loading="processingToTheNextPage"
        :disabled="!checked"
      >
        Przejdź do Portalu Pacjenta
      </a-button>
    </div>
  </div>
</template>

<script>
import { store, actions } from "@/store";
import RegulationText from "@/components/RegulationText";

export default {
  name: "Agreements",
  components: {
    RegulationText,
  },
  data() {
    return {
      checked: false,
      processingToTheNextPage: false,
      store,
    };
  },
  methods: {
    acceptAgreements() {
      this.processingToTheNextPage = true;
      this.$http.put(`patient-portal/api/agreements`).then(() => {
        this.processingToTheNextPage = false;
        this.store.user.agreements = true;
        actions.setUser(this.store.user);
        this.$router.push(this.$route.query.redirectedFrom || "/");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$agreements-box-width: 610px;
$agreements-box-height: 646px;
$agreements-box-margin: 5vh;

.agreements {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;

  .agreements__box {
    background-color: white;
    width: $agreements-box-width;
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: calc(
      100vh - #{$header-height} - #{$footer-height} - 2 *#{$agreements-box-margin}
    );

    @media (max-width: $agreements-box-width) {
      padding: 10px 16px 24px 16px;
      margin-top: 0;
      width: 100%;
      height: calc(100vh - #{$header-height} - #{$footer-height});
      overflow: visible;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 0;
      color: $c1;
      font-weight: normal;
    }

    .agreements__content {
      overflow: auto;
      margin-bottom: 24px;
    }
    .agreements__checkbox {
      margin-bottom: 28px;

      .agreements__checkbox--label {
        display: inline-block;
        font-size: 12px;
      }
    }

    .agreements__button {
      flex: 0 0 auto;
      width: fit-content;
      height: 40px;
    }
  }
}
</style>
